import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
  Location,
  Appearance,
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { Marker } from '../shared/datamodel/marker.model';
import { Address } from '../shared/datamodel/address.model';
import { MapsService } from '../shared/services/maps.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import * as actionAddress from '../store/actions/Address.action';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../shared/services/order.service';
import { Observable } from 'rxjs';
import { getAddress } from '../store/selectors/address.selectors';
import { User } from '../shared/datamodel/user.model';
import { getUser } from '../store/selectors/auth.selectors';
import { AddressService } from '../shared/services/address.service';
import { generateUUID } from '../shared/_helpers/must-match.validator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.css'],
  changeDetection:  ChangeDetectionStrategy.OnPush
})
export class GeolocationComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  title = 'Mi dirección';
  lat = 5.6909904;
  lng = -76.654901;
  zoom = 16;
  defaultSelected = '';
  selection: '';
  returnUrlDefout: 'returnUrl';
  returnUrl: string;
  Address$: Observable<Address[]>;
  ListAddress$: Observable<Address[]>;
  ListAddress: any[];
  user$: Observable<User>;
  UserData: User;
  formatted_address: string;
  appearance = Appearance;
  selectedAddress: PlaceResult;
  Addres_selection = '';
  typesOfAddress: string[] = [];
  AddressForm: FormGroup;
  submitted = false;
  Address: Address = {
    key: '',
    addressname: '',
    address: '',
    address2: '',
    description: '',
    lat: 0,
    lng: 0,
    city: '',
    state: '',
    country: '',
  };

  Marker: Marker = {
    lat: 5.6909904,
    lng: -76.654901,
    label: '',
    draggable: true,
    visibility: true,
    icon: 'https://firebasestorage.googleapis.com/v0/b/towndelivery-c9355.appspot.com/' +
           'o/icon%2Fcasa2.png?alt=media&token=35bd6cb8-f124-499f-bd12-a4d72f04169e',
  };

  private success = new Subject<string>();
  private danger = new Subject<string>();
  successMessage = '';
  dangerMessage = '';
  private result = 'results';

  constructor(
    private map: MapsService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
   this.unsubscribe$.next();
   this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.getInfoUser();
    this.getAddressData(this.lat, this.lng);
    this.AddressForm = this.formBuilder.group(
      {
        AddressName: ['', Validators.required],
        address: ['', Validators.required],
        address2: ['', Validators.required],
        description: ['', Validators.required],
        city: [{value: '', disabled: true}, Validators],
        state: [{value: '', disabled: true}, Validators],
        country: [{value: '', disabled: true}, Validators]
      },
    );
    this.locationStart();
  }

  onItemChange(item: Address) {
    this.Addres_selection = item.addressname;
  }

  public changeMessage(sw: boolean) {
    const messageD = sw ? 'SUCCESS!!' : 'Es posible que falten algunos campos requeridos';
    this.success.next(messageD);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.AddressForm.invalid) {
     // alert('Complete los campos del formulario!! :-)\n\n');
      Swal.fire({
        title: 'Error!',
        text: 'Complete los campos del formulario',
        icon: 'error',
        showCloseButton: true
      });
      return;
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Su dirección ha sido guardada',
        showConfirmButton: false,
        customClass: { popup: 'swal-wide', title: 'swal-title'},
        timer: 2500
      });
    }

    this.Address.addressname = this.AddressForm.value.AddressName;
    this.Address.address = this.AddressForm.value.address;
    this.Address.address2 = this.AddressForm.value.address2;
    this.Address.description = this.AddressForm.value.description;

   // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.Address));

    this.addAdressToList(this.Address);

    ///this.router.navigateByUrl(this.returnUrl);
  }

  addAdressToList(address: Address) {
    let listAddress = new Array<Address>();
    this.addressService.getAddress(this.UserData.uid);
    this.Address$ = this.store.select(getAddress);
    this.Address$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((AddressData) => {
      if (AddressData) {
        listAddress = AddressData;
      }
      this.cd.markForCheck();
    });
    address.key = generateUUID();
    listAddress.push(address);
    this.addressService.saveAddressLocalstorage(listAddress);
    this.store.dispatch(
      new actionAddress.SavedAddress({
        Address: listAddress,
        uid: this.UserData.uid,
        returnUrl: this.returnUrl,
      })
    );
  }

  getInfoUser() {
    this.user$ = this.store.select(getUser);
    this.user$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((userinfo) => {
      if (userinfo) {
        this.UserData = userinfo;
       // console.log('address: ', this.UserData.uid);
        this.getListAddress(this.UserData.uid);
      } else {
          if (this.returnUrl === '/' ) {
            this.returnUrl = 'geolocation';
          }
          this.router.navigate(['signin'], { queryParams: { returnUrl: this.returnUrl } } );
      }
      this.cd.markForCheck();
    });
  }

  getListAddress(uid: any) {
    this.ListAddress$ = this.store.select(getAddress);
    this.ListAddress$.subscribe(val => {this.ListAddress = val; });
    if (this.ListAddress === null) {
      this.store.dispatch(new actionAddress.GetAddress(uid));
    }
  }

  locationStart() {
    this.map.getPosition().then((pos) => {
      this.lat = pos.lat;
      this.lng = pos.lng;
      this.Marker.lat = pos.lat;
      this.Marker.lng = pos.lng;
      this.getAddressData(pos.lat, pos.lng);
     // console.log(`Positon: ${this.lng} ${this.lat}`);
    });
  }

  getAddressData(lat: any, lng: any) {
    this.typesOfAddress = [];
    this.Address.lat = lat;
    this.Address.lng = lng;
    this.map.getLocation(lat, lng).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data) => {
      data[this.result].forEach((element) => {
        this.typesOfAddress.push(element.formatted_address);
      });
      this.cd.markForCheck();
    });
  }
  get f() {
    return this.AddressForm.controls;
  }
  changeAddress(event: any) {    
    this.Address.address = event.split(',')[0];
    this.Address.city = event.split(',')[1];
    this.Address.state = event.split(',')[2];
    this.Address.country = event.split(',')[3];
    this.AddressForm.setValue({ AddressName: '',
                                address: this.Address.address,
                                address2: '',
                                description: '',
                                city: this.Address.city,
                                state: this.Address.state,
                                country: this.Address.country
                              });
  }

  onChoseLocation(event: any) {
    // console.log('evento del cursor', event);
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.getAddressData(this.lat, this.lng);
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.Address.address = result.formatted_address.split(',')[0];
    this.Address.city = result.formatted_address.split(',')[1];
    this.Address.state = result.formatted_address.split(',')[2];
    this.Address.country = result.formatted_address.split(',')[3];
  }

  onLocationSelected(location: Location) {
    this.lat = location.latitude;
    this.lng = location.longitude;
    this.Marker.lat = location.latitude;
    this.Marker.lng = location.longitude;
    this.getAddressData(this.lat, this.lng);
  }
}
