 import { Action } from '@ngrx/store';
 import { User } from '../../shared/datamodel/user.model';

 export enum AuthActionType {
     REGISTER_REQUESTED             = '[Auth] REGISTER Requested',
     REGISTER_COMPLETED             = '[Auth] REGISTER Completed',
     REGISTER_FAILED                = '[Auth] REGISTER Failed',
     VERIFICATION_EMAIL             = '[Auth] VERIFICATION Email',
     VERIFICATION_EMAIL_SUCCESS     = '[Auth] VERIFICATION Email success',
     UPDATE_PROFILE                 = '[Auth] Update profile',
     UPDATE_PROFILE_SUCCESS         = '[Auth] Update profile success',
     LOGIN_REQUESTED                = '[Auth] LOGIN Requested',
     LOGIN_SUCCESS                  = '[Auth] LOGIN Success',
     LOGIN_FAILED                   = '[Auth] LOGIN Failed',
     LOGIN_ANONYMUS_REQUESTED       = '[Auth] LOGIN Anonymus Requested',
     SOCIAL_LOGIN                   = '[Auth] Social media login',
     LOGOUT_REQUESTED               = '[Auth] LOGOUT requested',
     LOGOUT_COMPLETED               = '[Auth] LOGOUT completed',
     REQUESTED_PASSWORD_RESET       = '[Auth] REQUESTED Password reset',
     REQUESTED_PASSWORD_SUCCESS     = '[Auth] REQUESTED Password success',

     SAVE_USER                      = '[Auth] Save user',
     UPDATE_ONLINE_STATUS           = '[Auth] Update online status',
     CHECK_USER_ROLE                = '[Auth] Check user role',
     UPDATE_USER_ROLE               = '[Auth] Update user role',
     GET_USER                       = '[Auth] GET User',
     AUTH_ERROR                     = '[Auth] Error'
 }

 export class RegisterRequested implements Action {
     readonly type = AuthActionType.REGISTER_REQUESTED;

     constructor(public payload: { username: string, email: string, password: string, returnUrl: string} ) {}
 }

 export class RegisterCompleted implements Action {
     readonly type = AuthActionType.REGISTER_COMPLETED;
 }

 export class RegisterFailed implements Action {
     readonly type = AuthActionType.REGISTER_FAILED;

     constructor(public payload?: any) {}
 }

 export class VerificationEmail implements Action {
     readonly type = AuthActionType.VERIFICATION_EMAIL;

     constructor(public payload?: any) {}
 }

 export class VerificationEmailSuccess implements Action {
    readonly type = AuthActionType.VERIFICATION_EMAIL_SUCCESS;
 }

 export class UpdateProfile implements Action {
     readonly type = AuthActionType.UPDATE_PROFILE;

     constructor(public payload?: any) {}
 }
 export class UpdateProfileSuccess implements Action {
     readonly type = AuthActionType.UPDATE_PROFILE_SUCCESS;

     constructor(public payload?: any) {}
 }

 export class LoginRequested implements Action {
     readonly type = AuthActionType.LOGIN_REQUESTED;
     constructor(public payload?: any) {}
 }

 export class LoginAnonymusRequested implements Action {
    readonly type = AuthActionType.LOGIN_ANONYMUS_REQUESTED;
    constructor(public payload?: any) {}
}

 export class LoginSuccess implements Action {
     readonly type = AuthActionType.LOGIN_SUCCESS;
     constructor(public payload?: any) {}
 }

 export class LoginFailed implements Action {
     readonly type = AuthActionType.LOGIN_FAILED;
 }

 export class SocialLogin implements Action {
     readonly type = AuthActionType.SOCIAL_LOGIN;

     constructor(public payload?: any) {}
 }

 export class LogoutRequested implements Action {
     readonly type = AuthActionType.LOGOUT_REQUESTED;

     constructor(public payload?: any) {}
 }

 export class LogoutCompleted implements Action {
     readonly type = AuthActionType.LOGOUT_COMPLETED;
 }

 export class RequestedPasswordReset implements Action {
     readonly type = AuthActionType.REQUESTED_PASSWORD_RESET;
     constructor(public payload?: any) {}
 }

 export class RequestedPasswordSuccess implements Action {
    readonly type = AuthActionType.REQUESTED_PASSWORD_SUCCESS;
 }

 export class SaveUser implements Action {
     readonly type = AuthActionType.SAVE_USER;

     constructor(public payload?: any) {}
 }

 export class UpdateOnlineStatus implements Action {
     readonly type = AuthActionType.UPDATE_ONLINE_STATUS;
     constructor(public payload?: any) {}
 }

 export class CheckUserRole implements Action {
     readonly type = AuthActionType.CHECK_USER_ROLE;

     constructor(public payload?: any) {}
 }

 export class UpdateUserRole implements Action {
     readonly type = AuthActionType.UPDATE_USER_ROLE;

     constructor(public payload?: any) {}
 }

 export class GetUser implements Action {
     readonly type = AuthActionType.GET_USER;
 }

 export class AuthError implements Action {
     readonly type = AuthActionType.AUTH_ERROR;
     constructor(public payload?: any) {}
 }

 export type AuthAction = 
    | RegisterRequested
    | RegisterCompleted
    | RegisterFailed
    | VerificationEmail
    | VerificationEmailSuccess
    | UpdateProfile
    | UpdateProfileSuccess
    | LoginRequested
    | LoginAnonymusRequested
    | LoginSuccess
    | LoginFailed
    | RequestedPasswordReset
    | RequestedPasswordSuccess
    | SocialLogin
    | LogoutRequested
    | LogoutCompleted
    | SaveUser
    | UpdateOnlineStatus
    | CheckUserRole
    | UpdateUserRole
    | GetUser
    | AuthError
    ;



