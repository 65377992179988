import { Action } from '@ngrx/store';
import { Address } from '../../shared/datamodel/address.model';


export enum AddresActionType {
  GET_ADDRESS = '[Address] GET the address',
  LOAD_ADRESS = '[Address] load Address list',
  SAVE_ADDRESS = '[Address] SAVE the address',
  REMOVE_ADDRESS = '[Address] Remove Address from List_address',
  ADDRESS_ERROR = '[Address] Address error',
}

export class GetAddress implements Action {
  readonly type = AddresActionType.GET_ADDRESS;
  constructor(public payload?: any) {}
}

export class SavedAddress implements Action {
  readonly type = AddresActionType.SAVE_ADDRESS;
  constructor(public payload: { Address: Address[], uid: any, returnUrl: string  }) {}
}

export class LoadAddress implements Action {
  readonly type = AddresActionType.LOAD_ADRESS;
  constructor(public payload?: any) {}
}

export class RemoveAddress implements Action {
  readonly type = AddresActionType.REMOVE_ADDRESS;
  constructor(public payload: { Address: Address }) {}
}

export class AddressError implements Action {
  readonly type = AddresActionType.ADDRESS_ERROR;
  constructor(public payload: { error: any }) {}
}

export type AddressAction =
  | GetAddress
  | LoadAddress
  | SavedAddress
  | RemoveAddress
  | AddressError;
