import { IOrderBusiness, IOrder, Order } from '../../shared/datamodel/IOrder.model';

export interface OrderState {
    order: Order;
    error: any;
}

export interface OrderListState {
    Orderlist: IOrder[] | null;
    error: any;
}

export interface OrderBusinessListState {
    OrderBusinessList: IOrderBusiness[] | null;
    error: any;
}

export const orderInitialState: OrderState = {
    order: new Order(),
    error: null,
};

export const orderListInitialState: OrderListState = {
    Orderlist: null,
    error: null,
}

export const orderBusinessInitialState: OrderBusinessListState = {
    OrderBusinessList: null,
    error: null,
};
