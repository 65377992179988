import { Action } from '@ngrx/store';
import { IOrderBusiness, IOrder, Order } from '../../shared/datamodel/IOrder.model';

export enum OrderActionType {
  GET_ORDER                 = '[Order] GET the order',

  SAVE_ORDER                = '[Order] SAVE the order',
  SAVED_ORDER               = '[Order] SAVED the order ',
  CANCEL_ORDER              = '[Order] CANCEL order',
  CANCELLED_ORDER           = '[Order] CANCELLED order',
  COMPLETE_ORDER            = '[Order] COMPLETE order',
  ADD_ITEM                  = '[Order] Add item to order',
  ADD_ADDRESS               = '[Order] Add address to order',
  REMOVE_ITEM               = '[Order] Remove item from order',
  ORDER_ERROR               = '[Order] Order error',
  REPORT_ORDER              = '[Order] Order report',

  GET_ORDER_LIST            = '[Order] GET the order list',
  LOAD_ORDER_LIST           = '[Order] LOAD the order list',
  GET_ORDER_BUSINESS_LIST   = '[Order] GET the order business list',
  LOAD_ORDER_BUSINESS_LIST  = '[Order] LOAD the order business list',
}

export class GetOrder implements Action {
  readonly type = OrderActionType.GET_ORDER;
  constructor(
    public payload: {
      customer_key: string;
      status: string;
    }
  ) {}
}

export class GetOrderList implements Action {
  readonly type = OrderActionType.GET_ORDER_LIST;
  constructor(
    public payload: {
      CustomerKey: string
    }
  ) {}
}

export class LoadOrderList implements Action {
  readonly type = OrderActionType.LOAD_ORDER_LIST;
  constructor(public payload: { Orderlist: IOrder[] }) {}
}

export class GetOrderBusinessList implements Action {
  readonly type = OrderActionType.GET_ORDER_BUSINESS_LIST;
  constructor(public payload: {orderKey: string}) {}
}

export class LoadOrderBusinessList implements Action {
  readonly type = OrderActionType.LOAD_ORDER_BUSINESS_LIST;
  constructor(public payload: {OrderBusinessList: IOrderBusiness[] }) {}
}

export class SaveOrder implements Action {
  readonly type = OrderActionType.SAVE_ORDER;
  constructor(public payload: { order: Order, orderKey: string }) {}
}

export class SavedOrder implements Action {
  readonly type = OrderActionType.SAVED_ORDER;
  constructor(public payload: { order: Order }) {}
}

export class CancelOrder implements Action {
  readonly type = OrderActionType.CANCEL_ORDER;
  constructor(public payload: { order: Order }) {}
}

export class CancelledOrder implements Action {
  readonly type = OrderActionType.CANCELLED_ORDER;
  constructor(public payload: { order: Order }) {}
}

export class CompleteOrder implements Action {
  readonly type = OrderActionType.COMPLETE_ORDER;
  constructor(public payload: { order: Order }) {}
}

export class AddItem implements Action {
  readonly type = OrderActionType.ADD_ITEM;
  constructor(public payload: { order: Order }) {}
}

export class AddAddress implements Action {
  readonly type = OrderActionType.ADD_ADDRESS;
  constructor(public payload: { order: Order}) {}
}

export class RemoveItem implements Action {
  readonly type = OrderActionType.REMOVE_ITEM;
  constructor(public payload: { order: Order }) {}
}

export class OrderReport implements Action {
  readonly type = OrderActionType.REPORT_ORDER;
  constructor(public payload: { report: any }) {}
}

export class OrderError implements Action {
  readonly type = OrderActionType.ORDER_ERROR;
  constructor(public payload: { error: any, orderKey: string }) {}
}


export type OrederAction =
  | GetOrder
  | GetOrderList
  | GetOrderBusinessList
  | LoadOrderList
  | LoadOrderBusinessList
  | SaveOrder
  | SavedOrder
  | CancelOrder
  | CancelledOrder
  | CompleteOrder
  | AddItem
  | AddAddress
  | RemoveItem
  | OrderReport
  | OrderError;
