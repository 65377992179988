import { AddresActionType, AddressAction } from '../actions/Address.action';
import { AddressState, AddressInitialState  } from '../state/address.state';

export function addressReducer(
  state = AddressInitialState,
  action: AddressAction
): AddressState {
  switch (action.type) {
    case AddresActionType.GET_ADDRESS: {
      return Object.assign({}, state, {
        uid: action.payload
      });
    }

    case AddresActionType.SAVE_ADDRESS: {
      return Object.assign({}, state, {
        Address: action.payload.Address
      });
    }

    case AddresActionType.LOAD_ADRESS: {
      return Object.assign({}, state, {
        Address: action.payload.Address
      });
    }

    case AddresActionType.REMOVE_ADDRESS: {
      return state;
    }

    case AddresActionType.ADDRESS_ERROR: {
      return Object.assign({}, state, {
        error: action.payload.error
      });
    }

    default:
      return state;
  }
}
