import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { User } from '../shared/datamodel/user.model';
import { AppState } from '../store/reducers';
import { getError, getUser } from '../store/selectors/auth.selectors';
import * as actions from '../store/actions/auth.action';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

  user$: Observable<User>;
  error$: Observable<string | null>;
  returnUrl: string;
  constructor(private store: Store<AppState>,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || 'signin';
    this.user$ = this.store.select(getUser);

  }

  onSubmit(): void {
    this.store.dispatch(
      new actions.VerificationEmail({
        returnUrl: this.returnUrl
      })
    );
    this.HandlingError();
  }

  HandlingError() {
    this.error$ = this.store.select(getError);
  }

}
