import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../shared/datamodel/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { getError, getUser } from '../store/selectors/auth.selectors';
import { map } from 'rxjs/operators';
import * as actions from '../store/actions/auth.action';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  User: any = {
    email: '',
    password: ''
  };

  returnUrl: string;

  user$: Observable<User>;
  error$: Observable<string | null>;

  loginForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.user$ = this.store.select(getUser);
    //this.anonymusMessage();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.HandlingError();
  }

  HandlingError() {
    this.error$ = this.store.select(getError);
    
  }

  open(opened: boolean){
    setTimeout(function(){
         this.opened = !opened;
       },6000);
   }


  get f() {
    return this.loginForm.controls;
  }

  anonymusMessage() {
    this.user$.subscribe(user =>{
      if(user.isAnonymous) {
        Swal.fire({
          title: 'Anonymus!',
          text: 'Necesitamos algunos datos para completar tu Orden',
          icon: 'info',
          showCloseButton: true
        });
      }
    })
  }

  socialLogin(provider: string) {
    this.store.dispatch(new actions.SocialLogin({authProvider: provider}));
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.User.email = this.loginForm.value.email;
    this.User.password = this.loginForm.value.password;
    if (this.loginForm.valid) {
      this.store.dispatch(
        new actions.LoginRequested({
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
          returnUrl: this.returnUrl
        })
      );
    }
  }
}
