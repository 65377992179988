import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSidenav } from '@angular/material';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  
  private sidenav: MatSidenav;

  constructor() {}

    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    public toggle(): void {
    this.sidenav.toggle();
   }
}
