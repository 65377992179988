export interface Product {
  key?: string;
  businessKey?: string;
  businessName?: string;
  productRef: string;
  quotation: string;
  currency: string;
  productName: string;
  productCategory: string;
  category: string;
  milliliters: number;
  productPrice: number;
  productProfits: number;
  productPriceAmount: number;
  productDescription: string;
  productImageUrl: string;
  productQuatityStock: number;
  productQuatity: number;
  ratings: number;
  favourite: string;
  newP: string;
  status: boolean;
}
export interface SelectorOrder {
  value: string;
  viewValue: string;
}

export interface ConsultProduct {
  case: number;
  category: string;
  orderascdesc: string;
}

export class Products implements Product {
  key?: string;
  businessKey?: string;
  businessName?: string;
  productRef: string;
  quotation: string;
  currency: string;
  productName: string;
  productCategory: string;
  category: string;
  milliliters: number;
  productPrice: number;
  productProfits: number;
  productPriceAmount: number;
  productDescription: string;
  productImageUrl: string;
  productQuatityStock: number;
  productQuatity: number;
  ratings: number;
  favourite: string;
  newP: string;
  status: boolean;
  

  constructor() {
    this.favourite = 'true';
    this.productQuatity = 0;
    this.newP = 'true';
  }
}
