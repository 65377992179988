import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AngularMaterialModule } from '../app/angular-material.module';
import 'hammerjs';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent, DialogContentTC } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthService } from './shared/services/auth.service';
import { NavigationComponent } from './navigation/navigation.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LivechatWidgetModule } from '@livechat/angular-widget';
/**    REDUCERS   */
import { StoreModule} from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { OrderService } from './shared/services/order.service';
import { MapsService } from './shared/services/maps.service';
import { HttpClientModule } from '@angular/common/http';
import { ChatService } from './shared/services/chat.service';
import { ChatComponent } from './layout/components/chat/chat.component';
import { AngularMapModule } from './angular-map.module';
import { MaterialElevationDirective } from './material-elevation.directive';
import { SideNavService } from './shared/services/side-nav.service';
import { ChecktransactionService } from './shared/services/checktransaction.service';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
@NgModule({
})
export class NoopModule {}

const googleMapsParams = {
  apiKey: environment.apiKey,
  libraries: ['places'],
  language: 'es',
  // region: 'DE'
};
@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    MaterialElevationDirective,
    ChatComponent,
    DialogContentTC,
    EmailConfirmationComponent
  ],
  entryComponents: [DialogContentTC],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    LivechatWidgetModule,
    AngularMapModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
      StoreModule.forRoot(reducers, {metaReducers}),
      environment.production ? NoopModule : StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      EffectsModule.forRoot([AppEffects])
  ],
  providers: [AuthService, OrderService, MapsService, ChatService, SideNavService, ChecktransactionService],
  bootstrap: [AppComponent]
})
export class AppModule {}
