import { User } from './user.model';

export interface cartState {
  count: number;
}

export interface UserState {
  user: User;
}

export const UserInitialState: UserState = {
  user: new User(),
};

export const initialcartState = 0;
