import { Product } from 'src/app/shared/datamodel/product.model';

export interface ProductState {
    products: Product[] | null;
    error: any;
}

export const productInitialState: ProductState = {
    products: null,
    error: null
}