import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChecktransactionService {

  private wompi_transation_url = environment.wompi_transation_url;

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError({
      error: error.error.error.reason,
      status: error.status
    });
  };
  
  constructor(private http: HttpClient) { }
  GetStatusTransactionn(Id: any) {
    return this.http.get(
      `${this.wompi_transation_url}${Id}`
    ).pipe( catchError(this.handleError));
  }


}
