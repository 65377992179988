import { OrederAction, OrderActionType } from '../actions/OrderAction.action';
import {
  OrderState,
  orderInitialState,
  OrderListState,
  orderListInitialState,
  orderBusinessInitialState,
  OrderBusinessListState,
} from '../state/Order.state';

export function orderReducer(
  state = orderInitialState,
  action: OrederAction
): OrderState {
  switch (action.type) {
    case OrderActionType.SAVE_ORDER: {
      return Object.assign({}, state, {
        order: action.payload.order,
      });
    }

    case OrderActionType.ADD_ITEM: {
      return Object.assign({}, state, {
        order: action.payload.order,
      });
    }

    case OrderActionType.ADD_ADDRESS: {
      return Object.assign({}, state, {
        order: action.payload.order,
      });
    }

    case OrderActionType.SAVED_ORDER: {
      return Object.assign({}, state, {
        order: action.payload.order,
      });
    }

    case OrderActionType.CANCEL_ORDER: {
      return state;
    }

    case OrderActionType.CANCELLED_ORDER: {
      return state;
    }

    case OrderActionType.COMPLETE_ORDER: {
      return Object.assign({}, state, action.payload);
    }

    case OrderActionType.REPORT_ORDER: {
      return Object.assign({}, state, {
        report: action.payload,
      });
    }

    case OrderActionType.ORDER_ERROR: {
      return Object.assign({}, state, {
        error: action.payload.error,
      });
    }

    default:
      return state;
  }
}

export function oredrListReducer(
  state = orderListInitialState,
  action: OrederAction
): OrderListState {
  switch (action.type) {
    case OrderActionType.GET_ORDER_LIST:
      return Object.assign({}, state, {
        customerkey: action.payload.CustomerKey,
      });

    case OrderActionType.LOAD_ORDER_LIST:
      return Object.assign({}, state, {
        Orderlist: action.payload.Orderlist,
      });

    case OrderActionType.ORDER_ERROR:
      return Object.assign({}, state, {
        error: action.payload.error,
      });

    default:
      return state;
  }
}

export function OrderBusinessListReducer(
  state = orderBusinessInitialState,
  action: OrederAction
): OrderBusinessListState {
  switch (action.type) {
    case OrderActionType.GET_ORDER_BUSINESS_LIST:
      return Object.assign({}, state, {
        orderKey: action.payload.orderKey,
      });

    case OrderActionType.LOAD_ORDER_BUSINESS_LIST:
      return Object.assign({}, state, {
        OrderBusinessList: action.payload.OrderBusinessList,
      });

    case OrderActionType.ORDER_ERROR:
      return Object.assign({}, state, {
        error: action.payload.error,
      });

    default:
      return state;
  }
}
