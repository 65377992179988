import { Action } from '@ngrx/store';
import { Product, ConsultProduct } from '../../shared/datamodel/product.model';

export enum ProductActionType {
    GET_PRODUCT              = '[Product] Get Product',
    SAVE_PRODUCT             = '[Product] Save Product',
    UPDATE_PRODUCT           = '[Product] Update Product',
    DELETE_PRODUCT           = '[Product] Delete Product',
    ERROR_PRODUCT            = '[Product] Error Product'

 }

export class GetProduct implements  Action {
    readonly type = ProductActionType.GET_PRODUCT;
    constructor(public payload: { ConsultProduct: ConsultProduct}) {}
}

export class SaveProduct implements Action {
    readonly type = ProductActionType.SAVE_PRODUCT;
    constructor(public payload: { products: Product[]} ) {}
}

export class ErrorProduct implements Action {
    readonly type = ProductActionType.ERROR_PRODUCT;
    constructor(public payload: { error: any}) {}
}

export type ProductAction =
                            GetProduct
                            | ErrorProduct
                            | SaveProduct;
