import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import * as actionAddress from '../../store/actions/Address.action';
import * as firebase from 'firebase';
import 'firebase/firestore';

import { Address } from '../datamodel/address.model';
import { map } from 'rxjs/operators';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  Address$: Observable<Address>;
  private itemsCollection: AngularFirestoreCollection<any>;

  constructor(
    private afs: AngularFirestore,
    private store: Store<AppState>
  ) {}

   getAddress(uid: any) {
    this.itemsCollection = this.afs.collection<any>('Address', (ref) => {
      return ref.where('ownerkey', '==', `${uid}`);
    });
    return this.itemsCollection.snapshotChanges();

 /*



    console.log('Buscando direccion', uid);
    const docRef = this.afs.collection('Address').doc(uid);
    const result  =  docRef.snapshotChanges().pipe(
      map((action) => {
        let data = null;
        if (action.payload.exists) {
          data = action.payload.data();
          console.log('Lista::: ', data.list);
        } else {

        }

        return data.list;
      })
    );
      
    return this.afs.collection<Address[]>('Address').doc(uid).snapshotChanges();
//.subscribe(result => console.log('AFS: ',result.payload.data()));
  this.afs.collection('Address').doc(uid).snapshotChanges()
    .subscribe(result => console.log('AFS: ', result.payload.data()));

    return this.afs.collection('Address').doc(uid).snapshotChanges();*/
  }


  createAddress(address: Address [], uid: any) {
    // console.log(`uid: ${uid}`);
    return this.afs.collection('Address')
      .doc(uid).set({ownerkey: uid, list: address});
  }

  updateAddress(address: Address) {
    delete address.key;
    return this.afs.doc(`Address/${address.key}`).update(address);
  }

  _deleteAddress(keyAddress: string) {
    return this.afs.doc(`Address/${keyAddress}`).delete();
  }

  saveAddressLocalstorage(ListAddress: Address[]) {
    localStorage.setItem('ListAddress', JSON.stringify(ListAddress));
  }

  getAddresslocalStorage() {
    const ListAddress = localStorage.getItem('ListAddress');
    let AddressData: Address[];
    AddressData = JSON.parse(ListAddress);
   // console.log('Obteniendo roder de localstore: ', orderData);
  /*  if (AddressData) {
      this.store.dispatch(
        new actionAddress.SavedAddress({
          Address: AddressData,
          uid: '',
          returnUrl: '',
        })
      );
    }*/

    return AddressData;
  }

}
