import { Component, OnInit, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from './shared/datamodel/user.model';
import {
  getUser,
  getIsLoading,
  getIsAdmin,
} from './store/selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from './store/reducers';
import * as fromAuth from './store/actions/auth.action';
import { Order } from './shared/datamodel/IOrder.model';
import { getOrder } from './store/selectors/order.selectors';
import { OrderService } from './shared/services/order.service';
import { AddressService } from './shared/services/address.service';
import { environment } from 'src/environments/environment';

import * as actionProduct from './store/actions/product.action';
import * as actionAddress from './store/actions/Address.action';
import { ConsultProduct } from './shared/datamodel/product.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Licorsia';
  user$: Observable<User | null>;
  Order$: Observable<Order | null>;
  isLoggedIn$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  public opened = false;
  animal: string = environment.animal;
  public JsonConsult: ConsultProduct = {
    case: 0,
    category: '',
    orderascdesc: ''
  };
  constructor(
    private store: Store<AppState>,
    private ServerOrder: OrderService,
    private addressService: AddressService
  ) {}

  ngOnInit() {
    if (isDevMode()) {
      console.log('👋 Development!', this.animal);
    } else {
      console.log('💪 Production!', this.animal);
    }
    this.getUser();
    this.isLoading$ = this.store.select(getIsLoading);
    this.isAdmin$ = this.store.select(getIsAdmin);
    this.ServerOrder.getOrderlocalStorage();
    this.addressService.getAddresslocalStorage();
  }
  public close() {
    this.opened = false;
  }
  public OpenAndClose() {
    window.open('https://wa.me/573147472309', '_blank');
  }

  getUser() {
    this.user$ = this.store.select(getUser);
    this.user$.subscribe(reslutl => {
      if (reslutl) {
        this.getListAddress(reslutl.uid);
      }
      this.getProduct();
    });
  }

  getListAddress(uid: any) {
    this.store.dispatch(new actionAddress.GetAddress(uid));
  }

  getProduct() {
    this.store.dispatch(new actionProduct.GetProduct({ConsultProduct: this.JsonConsult}));
  }



  onLogout(user: User) {
    this.store.dispatch(new fromAuth.LogoutRequested({ user }));
  }
}
