import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import 'firebase/firestore';

import { Mensaje } from '../datamodel/mensaje.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private itemsCollection: AngularFirestoreCollection<Mensaje>;
  public chats: Mensaje[] = [];
  public usuario: any = {};
  private ItemRef: AngularFireList<any>;
  private userlist: any[] = [];

  constructor(
    private afs: AngularFirestore,
    public afauth: AngularFireAuth,
    private db: AngularFireDatabase
  ) {
    this.afauth.authState.subscribe((user) => {
      // console.log('Estado del usuario: ', user);
      if (!user) {
        return;
      }
      this.usuario.nombre = user.displayName;
      this.usuario.uid = user.uid;
    });
    this.getListUser();
    //console.log('Data info usr: ', this.userlist);
  }

  getListUser() {
    this.ItemRef = this.db.list('users');
    this.ItemRef.snapshotChanges(['child_added']).subscribe((actions) => {
      actions.forEach((action) => {
        this.userlist.push(action.payload.val());
      });
    });
  }

  cargarMensajes(useruid: any) {
    //console.log(useruid);
    this.itemsCollection = this.afs
      .collection('ChatDelivery')
      .doc('message')
      .collection<Mensaje>(`${useruid}`, (ref) =>
        ref.orderBy('date', 'desc')
      );
    return this.itemsCollection.valueChanges().pipe(
      map((mensajes: Mensaje[]) => {
       // console.log(mensajes);
        this.chats = [];
        for (const mensaje of mensajes) {
          this.chats.unshift(mensaje);
        }
        return this.chats;
      })
    );
  }

  cargarMensajesRoms(guestUid: any) {
    this.db.database
      .ref(`users/${guestUid}`)
      .once('value')
      .then( (snapshot) => {
        const username = (snapshot.val() && snapshot.val().uid) || 'Anonymous';
       // console.log(username);
      });

    this.itemsCollection = this.afs.collection<Mensaje>('ChatDelivery', (ref) =>
      ref.orderBy('date', 'desc').limit(100)
    );
    return this.itemsCollection.valueChanges().pipe(
      map((mensajes: Mensaje[]) => {
        //console.log(mensajes);
        this.chats = [];
        for (const mensaje of mensajes) {
          this.chats.unshift(mensaje);
        }
        return this.chats;
      })
    );
  }

  agregarMensaje(texto: string) {
    const mensaje: Mensaje = {
      name: this.usuario.nombre,
      message: texto,
      date: new Date().getTime(),
      uid: this.usuario.uid,
    };

    return this.itemsCollection.add(mensaje);
  }
}
