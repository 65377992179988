import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { User } from '../shared/datamodel/user.model';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import {
  getOrderCount,
  getOrder,
  getOrderCost,
} from '../store/selectors/order.selectors';
import { getUser } from '../store/selectors/auth.selectors';
import { MatSidenav } from '@angular/material';
import { SideNavService } from '../shared/services/side-nav.service';
import { Product } from '../shared/datamodel/product.model';
import { Order } from '../shared/datamodel/IOrder.model';
import { ProductService } from '../shared/services/product.service';
import { OrderService } from '../shared/services/order.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;
  @ViewChild('drawer', { static: true }) public drawer: MatSidenav;
  @Input() openNav: boolean;
  @Input() user: User;
  @Input() isLoggedIn: boolean;
  @Input() isLoading: boolean;
  @Input() isAdmin: boolean;
  @Output() logout = new EventEmitter<User>();
  private unsubscribe$ = new Subject<void>();
  

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  count$: Observable<number>;
  User$: Observable<User>;
  totalValue$: Observable<number>;

  

  count_product_value = '';

  nameUser = '';

  next = false;

  addExtraClass = false;
  isLogin = false;
  Products$: Observable<Product[]>;

  Order$: Observable<Order>;
  listProduct = new Array<Product>();
  totalValue = 0;

  constructor(
    public ServiceProduct: ProductService,
    public ServiceOrder: OrderService,
    private sideNavService: SideNavService,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>
  ) {
    this.count$ = this.store.select(getOrderCount);
    this.totalValue$ = this.store.select(getOrderCost);
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {    
    this.getListItemOrder();
    this.sideNavService.setSidenav(this.sidenav);
  }

  getUser() {
    this.User$ = this.store.select(getUser);
    this.User$.subscribe(reslutl => {
     // console.log(reslutl)
      if(reslutl) {
      if (reslutl.email !== null) {
        this.isLogin = true;       
      } else {
        this.isLogin = false;
      }
    } else{
      this.isLogin = false;
    }

    });
  }

  ngOnChanges(): void {
    this.getTotalValue();
    this.getUser();
  }

  shoppinClick() {
    this.sideNavService.toggle();
    this.drawer.toggle();
  }

  getTotalValue() {
    this.totalValue = 0;
    this.listProduct.forEach((product) => {
      this.totalValue += product.productPriceAmount;
    });
    if (this.totalValue > 0) {
      this.next = true;
    } else {
      this.next = false;
    }
    
  }

  getListItemOrder() {
    this.Order$ = this.store.select(getOrder);
    this.Order$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((order) => {
      if (order.items) {
        this.listProduct = order.items;
      }
    });
  }

  changeOwner(Quatity: any, key: any, businessKey: string, operator: any) {
    this.ServiceOrder.ChangeProductToOrder(Quatity, key, businessKey, operator);
    this.getTotalValue();
  }

  removeItemFromCart(Product: Product) {
    this.ServiceOrder.removeItemFromCart(Product.key, Product.businessKey);
    this.getTotalValue();
  }

  onLogout() {
    this.logout.emit(this.user);
    this.drawer.toggle();
   // this.store.dispatch(new fromAuth.LogoutRequested({ user: this.user }));
  }
}
