import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';

import * as fromCART from '../reducers/reducers_cart.reducer';
import * as fromAuth from '../reducers/auth.reducer';
import * as fromOrder from '../reducers/order.reducer';
import * as fromProduct from '../reducers/product.reducer';
import * as fromAddress from '../reducers/address.reducer';

import { AuthState } from '../state/auth.state';
import { OrderBusinessListState, OrderListState, OrderState } from '../state/Order.state';
import { ProductState } from '../state/product.state';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AddressState } from '../state/address.state';

export interface AppState {
  count: number;
  auth: AuthState;
  address: AddressState;
  order: OrderState;
  orderlist: OrderListState;
  OrderBusinessList: OrderBusinessListState;
  products: ProductState;
}


export const reducers: ActionReducerMap<AppState> = {
  count: fromCART.counterReducer,
  auth: fromAuth.authReducer,
  address: fromAddress.addressReducer,
  order: fromOrder.orderReducer,
  orderlist: fromOrder.oredrListReducer,
  OrderBusinessList: fromOrder.OrderBusinessListReducer,
  products: fromProduct.ProductReducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: ['AppState'], rehydrate: true })(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];
