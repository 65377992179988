import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import 'firebase/firestore';

import { Product } from '../datamodel/product.model';
import { Order } from '../datamodel/IOrder.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { getOrder } from 'src/app/store/selectors/order.selectors';
import * as actionOrder from '../../store/actions/OrderAction.action';
import { IBusiness } from '../datamodel/business.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  Order$: Observable<Order>;
  Business$: Observable<IBusiness>;
  listProduct$: Observable<Product[]>;
  private itemsCollection: AngularFirestoreCollection<Product>;
  private _isAdded$ = new Subject<void>();

  constructor(private afs: AngularFirestore, private store: Store<AppState>) {}

  /*  getProduct() {
    return this.afs.collection('Products').snapshotChanges();
  } */

  getBusinessProductList() {
    return this.afs.collection<Product>('BusinessProducts').snapshotChanges();
  }

  getBusinessProductListLimit(JsonConsult: any) {
    const consult = JsonConsult.case;
    const category = JsonConsult.category;
    const orderASCDESC = JsonConsult.orderascdesc;
    switch (consult) {
      case 1:
        this.itemsCollection = this.afs.collection<Product>(
          'BusinessProducts',
          (ref) => {
            return ref.where('productCategory', '==', category).limit(20);
          }
        );
        break;
      case 2:
        this.itemsCollection = this.afs.collection<Product>(
          'BusinessProducts',
          (ref) => {
            return ref.orderBy('productPrice', orderASCDESC).limit(20);
          }
        );
        break;

      case 3:
        this.itemsCollection = this.afs.collection<Product>(
          'BusinessProducts',
          (ref) => {
            return ref
              .where('productCategory', '==', `${category}`)
              .orderBy('productPrice', orderASCDESC);
          }
        );
        break;

      case 4:
        this.itemsCollection = this.afs.collection<Product>(
          'BusinessProducts',
          (ref) => {
            return ref.limit(20);
          }
        );
        break;

      default:
        this.itemsCollection = this.afs.collection<Product>(
          'BusinessProducts',
          (ref) => {
            return ref.limit(20);
          }
        );
        break;
    }

    return this.itemsCollection.snapshotChanges();
  }

  getBusinessByKey(BusinessKey: string) {
    const docRef = this.afs.collection<IBusiness>('Business').doc(BusinessKey);
    this.Business$ = docRef.snapshotChanges().pipe(
      map((action) => {
        let busineData = null;
        if (action.payload.exists) {
          busineData = action.payload.data();
          busineData.key = action.payload.id;
        }
        return busineData;
      })
    );
    return this.Business$;
  }

  /*  createProduct(product: Product) {
    return this.afs.collection('Products').add(product);
  } */

  /* updateProduct(product: Product) {
    delete product.key;
    return this.afs.doc(`Products/${product.key}`).update(product);
  } */

  /*  _deleteProduct(keyProduct: string) {
    return this.afs.doc(`Products/${keyProduct}`).delete();
  } */

  getQuantityListProduct(listP: Product[]) {
    let result: number;
    result = 0;
    listP.forEach((item) => {
      result = parseFloat('' + result) + parseFloat('' + item.productQuatity);
    });

    return result;
  }

  countProductquantity(listP: Product[], product: Product) {
    let sw = false;
    listP.forEach((item) => {
      if (item.key === product.key) {
        item.productQuatity = item.productQuatity + 1;
        item.productPriceAmount = item.productQuatity * item.productPrice;
        sw = true;
      }
    });
    if (!sw) {
      product.productQuatity = 1;
      product.productPriceAmount =
        product.productQuatity * product.productPrice;
      listP.push(product);
    }

    return listP;
  }

  /* Add product to Order */
  addProductToOrder(product: Product) {
    this.Order$ = this.store.select(getOrder);
    let orderD = new Order();

    this.getBusinessByKey(product.businessKey).subscribe((businessData) => {
      this.Order$.subscribe((orderData) => {
        if (orderData) {
          orderD = orderData;
        }
      });

      let listProduct = new Array<Product>();

      if (orderD.items === undefined) {
        product.productQuatity = 1;
        product.productPriceAmount =
          product.productQuatity * product.productPrice;
        listProduct.push(product);

        orderD = this.MapProductOrder(
          orderD,
          businessData.key,
          businessData.businessName,
          businessData.shipping,
          product
        );

        orderD.count = this.getQuantityListProduct(listProduct);
        orderD.items = listProduct;
        orderD.cost = this.getTotalValue(listProduct);

        // console.log('TotalValue: ', orderD.cost);
      } else {
        listProduct = orderD.items;
        listProduct = this.countProductquantity(listProduct, product);
        orderD.count = this.getQuantityListProduct(listProduct);
        orderD.items = listProduct;
        orderD = this.MapProductOrder(
          orderD,
          businessData.key,
          businessData.businessName,
          businessData.shipping,
          product
        );
        orderD.cost = this.getTotalValue(listProduct);

        // console.log('TotalValue: ', orderD.cost);
      }
      // console.log(orderD);
      this.store.dispatch(new actionOrder.AddItem({ order: orderD }));
      this.saveOrderLocalstorage(orderD);
    });
  }

  MapProductOrder(
    orderD: Order,
    businesskey: string,
    businessName: string,
    shipping: number,
    product: Product
  ) {
    let listProductByBusiness = new Array<Product>();
    let MapBusiness = new Map();
    let cost = 0;
    if (orderD.MapBusiness === undefined || orderD.MapBusiness === null) {
      // console.log(product.productPriceAmount);
      listProductByBusiness.push(product);
      cost = this.getTotalValue(listProductByBusiness);
      MapBusiness.set(businesskey, {
        items: listProductByBusiness,
        cost,
        businessName,
        shipping,
      });
      // console.log(MapBusiness);
      orderD.MapBusiness = this.MapToString(MapBusiness);
    } else {
      MapBusiness = this.StringToMap(orderD.MapBusiness);
      // console.log(MapBusiness);
      if (MapBusiness.get(businesskey) === undefined) {
        //  console.log(product.productPriceAmount);
        listProductByBusiness.push(product);
        cost = this.getTotalValue(listProductByBusiness);
        MapBusiness.set(businesskey, {
          items: listProductByBusiness,
          cost,
          businessName,
          shipping,
        });
        //console.log(this.MapToString(MapBusiness));
        orderD.MapBusiness = this.MapToString(MapBusiness);
      } else {
        listProductByBusiness = MapBusiness.get(businesskey).items;
        listProductByBusiness = this.countProductquantity(
          listProductByBusiness,
          product
        );
        (cost = this.getTotalValue(listProductByBusiness)),
          MapBusiness.set(businesskey, {
            items: listProductByBusiness,
            cost,
            businessName,
            shipping,
          });
        // console.log(this.MapToString(MapBusiness));

        orderD.MapBusiness = this.MapToString(MapBusiness);
      }
    }
    return orderD;
  }

  MapToString(map: Map<any, any>): string {
    const ro = {};
    map.forEach((value, key) => {
      ro[key] = value;
    });

    return JSON.stringify(ro);
  }

  StringToMap(mapBusiness: string): Map<any, any> {
    const MapBusiness = new Map();
    const Data = JSON.parse(mapBusiness);
    if (Data) {
      for (const value in Data) {
        MapBusiness.set(value, Data[value]);
        // console.log(MapBusiness);
      }
    }
    return MapBusiness;
  }

  getTotalValue(listProduct: Product[]) {
    let totalValue = 0;
    listProduct.forEach((product) => {
      totalValue = totalValue + product.productPriceAmount;
    });
    return totalValue;
  }

  saveOrderLocalstorage(order: Order) {
    localStorage.setItem('AppState', JSON.stringify(order));
  }
}
