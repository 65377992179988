import { Injectable, ErrorHandler } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference,
} from '@angular/fire/firestore';
import 'firebase/firestore';

import { IOrderBusiness, Order, OrderReport } from '../datamodel/IOrder.model';
import { Observable } from 'rxjs';
import { Product } from '../datamodel/product.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import * as actionOrder from '../../store/actions/OrderAction.action';
import { ProductService } from './product.service';
import { getOrder } from 'src/app/store/selectors/order.selectors';
import { Address } from '../../shared/datamodel/address.model';
import { map } from 'rxjs/operators';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  Order$: Observable<Order>;
  OrderList$: Observable<Order[]>;
  OrderList: Array<Order> = [];
  private itemsCollection: AngularFirestoreCollection<Order>;
  todoCollectionRef: AngularFirestore;
  todo$: Observable<Order[]>;
  swt = false;
  
  constructor(
    private afs: AngularFirestore,

    private store: Store<AppState>,
    private ServiceProduct: ProductService
  ) {}

  getOrderList(CustomerKey: string) {
    

    this.itemsCollection = this.afs.collection<Order>('Orders', (ref) => {
      return ref.where('CustomerKey', '==', `${CustomerKey}`).orderBy('date', 'desc');
    });

    return this.itemsCollection.snapshotChanges();
  }

  getOrderBusinessList(OrderId: string) {
    //console.log('businesslist:', OrderId);
    this.itemsCollection = this.afs.collection<IOrderBusiness>('Orders').doc(OrderId).collection('OrderBusiness');
    // .subscribe(result =>{
    //   result.map(datalist => {
    //     console.log(datalist.payload.doc.data());
    //   })
    // });
    return this.itemsCollection.snapshotChanges();
  }
    
  getOrders(CustomerKey: string) {
    this.itemsCollection = this.afs.collection<Order>('Orders', (ref) => {
      return ref.where('CustomerKey', '==', `${CustomerKey}`);
    });

    this.OrderList$ = this.itemsCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => {
          const data = action.payload.doc.data() as Order;
          data.id = action.payload.doc.id;
          return { ...data };
        });
      })
    );

    return this.OrderList$;
  }

  getOrderBykey(OrderKey: string) {
    const docRef = this.afs.collection('Orders').doc(OrderKey);

    this.Order$ = docRef.snapshotChanges().pipe(
      map((action) => {
        let data = null;
        if (action.payload.exists) {
          data = action.payload.data();
          data.id = action.payload.id;
        }

        return data;
      })
    );

    return this.Order$;
  }

  getOrderByTransactionId(OrderKey: string) {
    const docRef = this.afs.collection('Orders', (ref) => {
      return ref.where('TransactionId', '==', `${OrderKey}`);
    });

    this.Order$ = docRef.snapshotChanges().pipe(
      map((Action) => {
        return Action.map((action) => {
            const data = action.payload.doc.data() as Order;
            data.id = action.payload.doc.id;
            return data;
          })[0];
      })
    );

    return this.Order$;
  }

  async createOrders(order: Order) {
    this.saveOrderLocalstorage(order);
    this.itemsCollection = this.afs.collection<Order>('Orders');
    const resultID = (await this.itemsCollection.add({ ...order })).id;
    let products: Product[] = [];
    products = order.items;
    const itemsProcessed = 0;
    const MapDocRef: Map<string, string> = new Map();
    let status: any;
    status = await this.MultipleTransactionQuantity(products, 1, null);
    // console.log('status:', status);
    return { docRef: resultID, status };
  }

  async MultipleTransactionQuantity(
    products: Product[],
    operation: number,
    key_p: any
  ) {
    const MapDocRef: Map<string, any> = new Map();
    const ArrayPromes: Array<any> = [];
    products.forEach(async (product) => {
      if (key_p === product.key) {
        // console.log('key with problm: ', key_p);
      } else {
        const sfDocRef = this.afs
          .collection('BusinessProducts')
          .doc(product.key).ref;
        ArrayPromes.push(
          this.DoTransactionQuantity(
            sfDocRef,
            product.key,
            product.productQuatity,
            operation
          )
        );
      }
    });

    const result = Promise.all(ArrayPromes)
      .then((value) => {
        // console.log(value);
        let swt = false;
        let url = 'payment';
        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          // console.log(element.includes('Error'));
          if (element.includes('Error')) {
            url = 'shopping-cart';
            swt = true;
            break;
          }
        }

        if (swt) {
          products.forEach(async (product) => {
            // MapDocRef.set(product.key, this.afs.collection('BusinessProducts').doc(product.key));
            let flag = false;
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              // console.log(element.includes(product.key));
              if (element.includes(product.key)) {
                flag = true;
                break;
              }
            }
            if (!flag) {
              // console.log('entro a rollback');
              const sfDocRef = this.afs
                .collection('BusinessProducts')
                .doc(product.key).ref;
              ArrayPromes.push(
                this.DoTransactionQuantity(
                  sfDocRef,
                  product.key,
                  product.productQuatity,
                  0
                )
              );
            }
          });
        }
        return url;
      })
      .catch((error) => {
        // console.error(error);
      });
    return result;
  }

  DoTransactionQuantity(
    sfDocRef: DocumentReference,
    productKey: any,
    productQuatity: number,
    operation: number
  ) {
    return this.afs.firestore.runTransaction( (transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(sfDocRef).then((sfDoc) => {
        if (!sfDoc.exists) {
          return `Error:${productKey}`;
        }
        let newproductQuatity = sfDoc.data().productQuatityStock;
        switch (operation) {
          case 1:
            newproductQuatity =
              sfDoc.data().productQuatityStock - productQuatity;
            break;

          default:
            newproductQuatity =
              sfDoc.data().productQuatityStock + productQuatity;
            break;
        }

        if (newproductQuatity < 0) {
          // throw new Error(`${productKey}`);
          return `Error:${productKey}`;
        }
        transaction.update(sfDocRef, {
          productQuatityStock: newproductQuatity,
        });
        return 'successfully';
      });
    });
  }

  updateQTY(key: string, value: number) {
    return this.afs
      .collection('BusinessProducts')
      .doc(key)
      .update((productQuatityStock: number) => {
        productQuatityStock + value;
      });
  }

  noValueException(Error: any) {
    return { message: Error.message, key: Error.key };
  }

  /** get array of key item and productQuatity */

  updateOrderTransaction(Transaction: any) {
    let status = 1;
    if (Transaction.status !== 'APPROVED') {
      status = 6;
    }
    return this.afs
      .collection('Orders')
      .doc(`${Transaction.reference}`)
      .update({
        payment: Transaction.status,
        transaction: Transaction,
        TransactionId: Transaction.id,
        status,
      });
  }

  updateOrderTransactionByRef(ref: any) {
    // console.log('actualizando');
    const status = 4;
    return this.afs.collection('Orders').doc(`${ref}`).update({
      status,
    });
  }

  createReportOrderTransaction(report: OrderReport) {
    return this.afs
      .collection('TransactionReport')
      .doc(report.id)
      .set({ ...report });
  }

  _deleteOrders(order: Order) {
    // return this.afs.doc(`Orders/${key_order}`).delete()
    delete order.id;
    order.status = 3;
    return this.afs.doc(`Orders/${order.id}`).update(order);
  }

  removeItemFromCart(key: any, businessKey: string) {
    let order = new Order();
    this.Order$ = this.store.select(getOrder);
    this.Order$.subscribe((OrderData) => {
      if (OrderData) {
        order = OrderData;
      }
    });

    let listProductByBusiness = new Array<Product>();
    let MapBusiness = new Map();
    MapBusiness = this.StringToMap(order.MapBusiness);
    listProductByBusiness = MapBusiness.get(businessKey).items;
    // console.log(listProductByBusiness);
    listProductByBusiness.map((item, index) => {
      if (item.key === key) {
        item.productQuatity = 0;
        listProductByBusiness.splice(index, 1);
      }
    });
    if (listProductByBusiness.length > 0) {
      const cost = this.getTotalValue(listProductByBusiness);
      const businessName = MapBusiness.get(businessKey).businessName;
      const shipping = MapBusiness.get(businessKey).shipping;
      MapBusiness.set(businessKey, {
        items: listProductByBusiness,
        cost,
        businessName,
        shipping
      });
     // console.log(listProductByBusiness);
     // console.log(MapBusiness);
    } else {
      MapBusiness.delete(businessKey);
    }
    order.MapBusiness = this.MapToString(MapBusiness);

    let listProduct = new Array<Product>();
    listProduct = order.items;

    listProduct.map((item, index) => {
      if (item.key === key) {
        item.productQuatity = 0;
        listProduct.splice(index, 1);
      }
    });

    order.count = this.ServiceProduct.getQuantityListProduct(listProduct);
    order.items = listProduct;
    order.cost = this.getTotalValue(listProduct);
    this.store.dispatch(new actionOrder.AddItem({ order }));
    this.saveOrderLocalstorage(order);
  }

  ChangeProductToOrder(
    Quatity: number,
    key: any,
    businessKey: string,
    operator: any
  ) {
    Quatity = this.RetunrQuantityOperator(Quatity, operator);
    if (Quatity == 0) {
      this.removeItemFromCart(key, businessKey);
    } else {
      let order = new Order();
      this.Order$ = this.store.select(getOrder);
      this.Order$.subscribe((OrderData) => {
        if (OrderData) {
          order = OrderData;
        }
      });

      let listProductByBusiness = new Array<Product>();
      let MapBusiness = new Map();
      MapBusiness = this.StringToMap(order.MapBusiness);
      listProductByBusiness = MapBusiness.get(businessKey).items;
      // console.log(listProductByBusiness);
      listProductByBusiness.map((item) => {
        if (item.key === key) {
          item.productQuatity = Quatity;
          item.productPriceAmount = item.productQuatity * item.productPrice;
        }
      });

      const cost = this.getTotalValue(listProductByBusiness);
      const businessName = MapBusiness.get(businessKey).businessName;
      const shipping = MapBusiness.get(businessKey).shipping;
      MapBusiness.set(businessKey, {
        items: listProductByBusiness,
        cost,
        businessName,
        shipping
      });
      // console.log(listProductByBusiness);
      // console.log(MapBusiness);

      order.MapBusiness = this.MapToString(MapBusiness);

      let listProduct = new Array<Product>();
      listProduct = order.items;
      listProduct.map((item) => {
        if (item.key === key) {
          item.productQuatity = Quatity;
          item.productPriceAmount = item.productQuatity * item.productPrice;
        }
      });
      order.count = this.ServiceProduct.getQuantityListProduct(listProduct);
      order.items = listProduct;
      order.cost = this.getTotalValue(listProduct);
      this.store.dispatch(new actionOrder.AddItem({ order }));
      this.saveOrderLocalstorage(order);
    }
  }

  getTotalValue(listProduct: Product[]) {
    let totalValue = 0;
    listProduct.forEach((product) => {
      totalValue += product.productPriceAmount;
    });
    return totalValue;
  }

  RetunrQuantityOperator(Quatity: any, operator: any) {
    switch (operator) {
      case 'plus':
        return parseFloat(Quatity) + 1;
      case 'less':
        return Quatity - 1;

      default:
        return Quatity;
    }
  }

  addAdressToOrder(address: Address) {
    let order = new Order();
    this.Order$ = this.store.select(getOrder);
    this.Order$.subscribe((OrderData) => {
      if (OrderData) {
        order = OrderData;
      }
    });
    order.addres = address;
    this.store.dispatch(new actionOrder.AddItem({ order }));
    this.saveOrderLocalstorage(order);
  }

  saveOrderLocalstorage(order: Order) {
    localStorage.setItem('AppState', JSON.stringify(order));
  }
  getOrderlocalStorage() {
    const order = localStorage.getItem('AppState');
    let orderData = new Order();
    orderData = JSON.parse(order);
    if (orderData) {
      this.store.dispatch(new actionOrder.AddItem({ order: orderData }));
    }
    return order;
  }

  MapToString(map: Map<any, any>): string {
    const ro = {};
    map.forEach((value, key) => {
      ro[key] = value;
    });
    return JSON.stringify(ro);
  }

  StringToMap(mapBusiness: string): Map<any, any> {
    const MapBusiness = new Map();
    const Data = JSON.parse(mapBusiness);
    if (Data) {
      for (const value in Data) {
        MapBusiness.set(value, Data[value]);
        // console.log(MapBusiness);
      }
    }
    return MapBusiness;
  }
}
