import { Component, OnInit} from '@angular/core';
import { User } from 'src/app/shared/datamodel/user.model';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/reducers';
import { Store } from '@ngrx/store';
import { getUser } from 'src/app/store/selectors/auth.selectors';
import { ChatService } from 'src/app/shared/services/chat.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  user$: Observable<User>;
  UserData: User;
  UserNam: any;
  mensaje = '';
  elemento: any;
  opened = false;
  cahtForm: FormGroup;
  submitted = false;

  constructor(private store: Store<AppState>, public chatService: ChatService, private formBuilder: FormBuilder) {
    this.user$ = this.store.select(getUser);
    this.getInfoUser();
    this.loadingMessage(this.opened);

  }

  get f() {
    return this.cahtForm.controls;
  }

  ngOnInit() {
    this.elemento = document.getElementById('app-mensajes');
    this.cahtForm = this.formBuilder.group({
      email: ['', [Validators.email]]
    });
  }

  getInfoUser() {
    this.user$.subscribe((userinfo) => {
      //  console.log('Info Usuario: ', userinfo);
      if (userinfo !== null) {
        this.opened = true;
        this.UserData = userinfo;
      } else {
        this.opened = false;
        Swal.fire({
          title: 'Error!',
          text: 'Play go to Login',
          icon: 'error',
          customClass: { popup: 'swal-wide', title: 'swal-title'},
          showCloseButton: true
        });
      }
    });
  }

  loadingMessage(open: boolean) {
    if (open) {
       this.chatService.cargarMensajes(this.UserData.uid).subscribe(() => {
    });
    }
  }

  enviar_mensaje() {
    //  console.log(this.mensaje);

    if (this.mensaje.length === 0) {
      return;
    }

    this.chatService
      .agregarMensaje(this.mensaje)
      .then(() => (this.mensaje = ''))
      .catch((err) => console.log('Error al enviar', err));
  }
}
