import {
  ProductState,
  productInitialState
} from '../state/product.state';
import { ProductAction, ProductActionType } from '../actions/product.action';

export function ProductReducer(
  state = productInitialState,
  action: ProductAction
): ProductState {
  switch (action.type) {
    case ProductActionType.GET_PRODUCT: {
      return Object.assign({}, state, { ConsultProduct: action.payload.ConsultProduct });
    }

    case ProductActionType.SAVE_PRODUCT: {
      return Object.assign({}, state, {
        products: action.payload.products,
      });
    }

    case ProductActionType.ERROR_PRODUCT: {
      return Object.assign({}, state, { error: action.payload });
    }

    default:
      return state;
  }
}

