import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, from } from 'rxjs';
import * as firebase from 'firebase/app';
import { User } from '../datamodel/user.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { getUser } from 'src/app/store/selectors/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User>;
  UserData: User = new User();

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private store: Store<AppState>
  ) {}

  /// anonymus Auth ///
  anonymousAuth() {
    if (this.afAuth.auth.currentUser !== null) {
      if (this.afAuth.auth.currentUser.isAnonymous) {
        this.afAuth.auth.currentUser.delete();
       } 
    }
    return this.afAuth.auth.signInAnonymously();
  }

  /// requested password reset ///
  requestePassword(email: string) {
    
    return from(this.afAuth.auth.sendPasswordResetEmail(email));
  }

  /// verification email fucntion ///
  verificationEmail(){
    return from(this.afAuth.auth.currentUser.sendEmailVerification());
  }




 /* switchToRealAuth(authProvider: string, User) {
    let previousUser = firebase.auth().currentUser;
    let credential;
    if (authProvider === 'google') {
      credential = firebase.auth.GoogleAuthProvider.credential(
        User.getAuthResponse().id_token);
    }

    if (authProvider === 'facebook') {
      credential = firebase.auth.FacebookAuthProvider.credential();

    }

    if (authProvider === 'twitter') {
      credential =  firebase.auth.TwitterAuthProvider.credential(token, secret);
    }

    return from(this.afAuth.auth.signInWithPopup(provider));
  }*/

  register(email: string, password: string) {
    return from(
      this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    );
  }

  updateProfile(displayName: string, photoURL: string) {
    const userProfile = this.afAuth.auth.currentUser;
    if (userProfile) {
      return  from(
        userProfile.updateProfile({
          displayName,
          photoURL,
        })
      ) as any;
    }
  }

  login(email: string, password: string) {
    if (this.afAuth.auth.currentUser !== null) {
      if (this.afAuth.auth.currentUser.isAnonymous) {
        this.afAuth.auth.currentUser.delete();
       }
    }
    return from(this.afAuth.auth.signInWithEmailAndPassword(email, password));
  }

  socialLogin(authProvider: string) {
  if (this.afAuth.auth.currentUser !== null) {
      if (this.afAuth.auth.currentUser.isAnonymous) {
        this.afAuth.auth.currentUser.delete();
       }
    }
  let provider: any;
  if (authProvider === 'google') {
      provider = new firebase.auth.GoogleAuthProvider();
    }

  if (authProvider === 'facebook') {
      provider = new firebase.auth.FacebookAuthProvider();
    }

  if (authProvider === 'twitter') {
      provider = new firebase.auth.TwitterAuthProvider();
    }

  return from(this.afAuth.auth.signInWithPopup(provider));
  }

  logout() {
    if (this.afAuth.auth.currentUser !== null) {
      if (this.afAuth.auth.currentUser.isAnonymous) {
        this.afAuth.auth.currentUser.delete();
       } 
    }
    return from(this.afAuth.auth.signOut());
  }

  saveUser(user: User) {
   
    if (!user.isAnonymous) {
       this.db.database.ref(`users`)
                .child(user.uid).once('value', (snapshot) => {
                  if(snapshot.exists()) {
                    if( snapshot.val().phoneNumber !== undefined ){
                      user.phoneNumber = user.phoneNumber === null ? snapshot.val().phoneNumber : user.phoneNumber;
                    }
                   // console.log('updaate User:',user);
                    return snapshot.ref.update({...user});
                  } else {
                    return snapshot.ref.set(user);
                  }
                });
    } else {
      
      return user;
    }

  }

  updateOnlineStatus(user: User, status: boolean) {
    if (!user.isAnonymous) {
      return from(this.db.object(`users/${user.uid}`).update({ isOnline: status }));
    }else {
      return user;
    }
    
  }

  checkUserRole(uid: string) {
    return this.db.object(`admins/${uid}`).valueChanges();
  }

  getAuthState() {
   // console.log(this.afAuth.authState);
    return this.afAuth.authState;
  }

  getCurrentUser() {
    return this.afAuth.auth.currentUser;
  }

  getInfoUser() {
    this.user$ = this.store.select(getUser);
    this.user$.subscribe((userinfo) => {
      if (userinfo) {
        this.UserData = userinfo;
      }
    });
    // console.log('Info Usuario: ', this.UserData);
    return this.UserData;
  }

}
