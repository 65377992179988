import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import * as actions from '../store/actions/auth.action';
import { Observable } from 'rxjs/internal/Observable';
import { getError } from '../store/selectors/auth.selectors';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  loginForm: FormGroup;
  error$: Observable<string | null>;
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.HandlingError();
  }

  HandlingError() {
    this.error$ = this.store.select(getError);
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    if (this.loginForm.valid) {
      this.store.dispatch(
        new actions.RequestedPasswordReset({
          email: this.loginForm.value.email,
          returnUrl: 'signin'
        })
      );
    }
  }

}
