import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { FirebaseErrors, User } from '../../shared/datamodel/user.model';

import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, of, defer, pipe } from 'rxjs';
/*
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';*/

import * as auth from '../actions/auth.action';
import * as ORder from '../actions/OrderAction.action';
import * as PRoducts from '../actions/product.action';
import * as ADdress from '../actions/Address.action';

import { map, switchMap, catchError, take, tap } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import {
  IOrder,
  IOrderBusiness,
  Order,
} from 'src/app/shared/datamodel/IOrder.model';
import { Products, Product } from '../../shared/datamodel/product.model';
import { AddressService } from 'src/app/shared/services/address.service';
import Swal from 'sweetalert2';

@Injectable()
export class AppEffects {
  
  constructor(
    private actions$: Actions,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private orderService: OrderService,
    private productService: ProductService,
    private addressService: AddressService,
    private router: Router
  ) {}

  showMessaggeEmail(email: string) {
   
    let message =
            'Se envio un email a:' +
            email +
            ', Por favor mira tu bandeja de entrada';

          Swal.fire({
            icon: 'success',
            title: message,
            showConfirmButton: false,
            customClass: { popup: 'swal-wide', title: 'swal-title' },
            timer: 3500,
          });
  }

  @Effect()
  registerAction$ = this.actions$.pipe(
    ofType(auth.AuthActionType.REGISTER_REQUESTED),
    map((action: auth.RegisterRequested) => action.payload),
    switchMap((payload) =>
      this.authService.register(payload.email, payload.password).pipe(
        map((res: any) => {
          const user = {
            uid: res.user.uid,
            displayName: payload.username || res.user.displayName,
            email: res.user.email,
            providerId: res.additionalUserInfo.providerId,
            photoUrl: res.user.photoURL,
            isNewUser: res.additionalUserInfo.isNewUser,
            isAnonymous: res.user.isAnonymous,
            isVerify: res.user.emailVerified,
            isAdmin: false,
            isOnline: true,
          };
          return user;
        }),
        switchMap((user: User) => {
          return [
            new auth.RegisterCompleted(),
            new auth.LoginSuccess({ user }),
            new auth.UpdateProfile({
              displayName: payload.username,
              photoUrl: user.photoUrl,
            }),
            new auth.VerificationEmail(payload.returnUrl),
          ];
        }),
        tap(() => {
          //this.router.navigateByUrl(payload.returnUrl);
          this.router.navigate(['emailConfirmation'], {
            queryParams: { returnUrl: payload.returnUrl },
          });
        }),
        catchError((errors) =>
          of(new auth.AuthError(FirebaseErrors.Parse(errors.code)))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  saveUser$ = this.actions$.pipe(
    ofType(auth.AuthActionType.SAVE_USER),
    map((action: auth.SaveUser) => action.payload),
    switchMap((payload: any) => {
      this.authService.saveUser(payload.user);
      return of(payload);
    })
  );

  @Effect()
  updateProfile$ = this.actions$.pipe(
    ofType(auth.AuthActionType.UPDATE_PROFILE),
    map((action: auth.UpdateProfile) => action.payload),
    switchMap((payload: any) =>
      this.authService
        .updateProfile(payload.displayName, payload.photoUrl)
        .pipe(
          map(() => {
            const currentUser: any = this.authService.getCurrentUser();
            const updatedUser: any = {
              uid: currentUser.uid || null,
              displayName: currentUser.displayName || null,
              email: currentUser.email || null,
              providerId: currentUser.providerData[0].providerId || null,
              photoUrl: currentUser.photoURL || null,
              isVerify: currentUser.emailVerified,
            };
            return new auth.UpdateProfileSuccess({ user: updatedUser });
          }),
          catchError((errors) =>
            of(new auth.AuthError(FirebaseErrors.Parse(errors.code)))
          )
        )
    )
  );

  @Effect()
  LoginSuccess$ = this.actions$.pipe(
    ofType(auth.AuthActionType.LOGIN_SUCCESS),
    map((action: auth.LoginSuccess) => action.payload),
    switchMap((payload: any) => {
      // console.log('Payload of loginSuccess: ', payload);
      return [
        new auth.SaveUser({ user: payload.user }),
        // new auth.UpdateOnlineStatus({ user: payload.user, status: true }),
        new auth.CheckUserRole({ uid: payload.user.uid }),
      ];
    })
  );

  @Effect()
  loginAction$ = this.actions$.pipe(
    ofType(auth.AuthActionType.LOGIN_REQUESTED),
    map((action: auth.LoginRequested) => action.payload),
    switchMap((payload) =>
      this.authService.login(payload.email, payload.password).pipe(
        map((res: any) => {
          const user = {
            uid: res.user.uid,
            displayName: res.user.displayName,
            email: res.user.email,
            providerId: res.additionalUserInfo.providerId,
            photoUrl: res.user.photoURL,
            phoneNumber: res.user.phoneNumber,
            isNewUser: res.additionalUserInfo.isNewUser,
            isAnonymous: res.user.isAnonymous,
            isVerify: res.user.emailVerified,
          };
          return new auth.LoginSuccess({ user, returnUrl: payload.returnUrl });
        }),
        tap(() => {
          this.router.navigateByUrl(payload.returnUrl);
        }),
        catchError((errors) => {
          return of(new auth.AuthError(FirebaseErrors.Parse(errors.code)));
        })
      )
    )
  );

  @Effect()
  requestedPassword$ = this.actions$.pipe(
    ofType(auth.AuthActionType.REQUESTED_PASSWORD_RESET),
    map((action: auth.RequestedPasswordReset) => action.payload),
    switchMap((payload: any) =>
      this.authService.requestePassword(payload.email).pipe(
        map((res: any) => {
          this.showMessaggeEmail(payload.email);
          return new auth.RequestedPasswordSuccess();
        }),
        tap(() => {
          this.router.navigateByUrl(payload.returnUrl);
        }),
        catchError((errors) => {
          console.error(FirebaseErrors.Parse(errors.code));
          return of(new auth.AuthError(FirebaseErrors.Parse(errors.code)));
        })
      )
    )
  );

  @Effect()
  verificationEmail$ = this.actions$.pipe(
    ofType(auth.AuthActionType.VERIFICATION_EMAIL),
    map((action: auth.VerificationEmail) => action.payload),
    switchMap((payload) =>
      this.authService.verificationEmail().pipe(
        map((res: any) => {
          
          this.showMessaggeEmail(this.afAuth.auth.currentUser.email); 
          return new auth.VerificationEmailSuccess();
        }),
        tap(() => {
          //this.router.navigateByUrl(payload.returnUrl);
        }),
        catchError((errors) => {
          return of(new auth.AuthError(FirebaseErrors.Parse(errors.code)));
        })
      )
    )
  );

  @Effect()
  loginAnonymus$ = this.actions$.pipe(
    ofType(auth.AuthActionType.LOGIN_ANONYMUS_REQUESTED),
    map((action: auth.LoginAnonymusRequested) => action.payload),
    switchMap((payload) =>
      this.authService
        .anonymousAuth()
        .then((res) => {
          // console.log('Result: ', res.user);
          const user = {
            uid: res.user.uid,
            displayName: res.user.displayName,
            email: res.user.email,
            providerId: res.additionalUserInfo.providerId,
            photoUrl: res.user.photoURL,
            isAnonymous: res.user.isAnonymous,
            isNewUser: res.additionalUserInfo.isNewUser,
            isVerify: res.user.emailVerified,
          };
          return { user, returnUrl: '/' };
        })
        .catch((error) => ({ error }))
    ),
    pipe(
      map((Result: any) => {
        // console.log(Result);
        return new auth.LoginSuccess({
          user: Result.user,
          returnUrl: Result.returnUrl,
        });
      })
    )
  );

  @Effect()
  socialLogin$ = this.actions$.pipe(
    ofType(auth.AuthActionType.SOCIAL_LOGIN),
    map((action: auth.SocialLogin) => action.payload),
    switchMap((payload) =>
      this.authService.socialLogin(payload.authProvider).pipe(
        map((res: any) => {
          const user = {
            uid: res.user.uid,
            displayName: res.user.displayName,
            email: res.user.email,
            providerId: res.additionalUserInfo.providerId,
            photoUrl: res.user.photoURL,
            phoneNumber: res.user.phoneNumber,
            isNewUser: res.additionalUserInfo.isNewUser,
            isAnonymous: res.user.isAnonymous,
            isVerify: res.user.emailVerified,
          };
          return user;
        }),
        switchMap((user: User) => {
          if (user.isNewUser) {
            return [
              new auth.SaveUser({ user }),
              new auth.LoginSuccess({ user, returnUrl: payload.returnUrl }),
              new auth.CheckUserRole({ uid: user.uid }),
            ];
          } else {
            return [
              new auth.LoginSuccess({ user, returnUrl: payload.returnUrl }),
              new auth.CheckUserRole({ uid: user.uid }),
            ];
          }
        }),
        tap(() => this.router.navigateByUrl(payload.returnUrl)),
        catchError((errors) => {
          return of(new auth.AuthError(FirebaseErrors.Parse(errors.code)));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  UpdateOnlineStatus$ = this.actions$.pipe(
    ofType(auth.AuthActionType.UPDATE_ONLINE_STATUS),
    map((action: auth.UpdateOnlineStatus) => action.payload),
    switchMap((payload: any) => {
      this.authService.updateOnlineStatus(payload.user, payload.status);
      return of(payload);
    })
  );

  @Effect()
  CheckUserRole$ = this.actions$.pipe(
    ofType(auth.AuthActionType.CHECK_USER_ROLE),
    map((action: auth.CheckUserRole) => action.payload),
    switchMap((payload: any) =>
      this.authService.checkUserRole(payload.uid).pipe(
        map((isAdmin: boolean) => {
          return new auth.UpdateUserRole({ isAdmin });
        }),
        catchError((errors: any) =>
          of(new auth.AuthError(FirebaseErrors.Parse(errors.code)))
        )
      )
    )
  );

  @Effect()
  logoutAction$ = this.actions$.pipe(
    ofType(auth.AuthActionType.LOGOUT_REQUESTED),
    map((action: auth.LogoutRequested) => action.payload),
    switchMap((payload: any) => {
      return this.authService.logout().pipe(
        map(() => new auth.LogoutCompleted()),
        tap(() => {
          this.router.navigateByUrl('');
        }),
        catchError((error) => {
          return of(
            new auth.AuthError({ error: FirebaseErrors.Parse(error.code) })
          );
        })
      );
    })
  );

  @Effect()
  getUser$ = this.actions$.pipe(
    ofType(auth.AuthActionType.GET_USER),
    switchMap(() =>
      this.authService.getAuthState().pipe(
        take(1),
        map((authData: any) => {
          if (authData) {
            
            const user = new User();
            user.uid = authData.uid;
            user.isVerify = authData.emailVerified;
            user.displayName = authData.displayName;
            user.email = authData.email;
            user.providerId =
              authData.providerData.length > 0
                ? authData.providerData[0].providerId
                : null;
            user.photoUrl = authData.photoURL;
            user.phoneNumber = authData.phoneNumber;
            user.isAnonymous = authData.isAnonymous;
            
            return user;
          } else {
            return null;
          }
        }),
        switchMap((user: User) => {
          if (user) {
            return [new auth.LoginSuccess({ user, returnUrl: '/' })];
          } else {
            return [new auth.LoginAnonymusRequested()];
          }
        }),
        catchError((errors) =>
          of(new auth.AuthError(FirebaseErrors.Parse(errors.code)))
        )
      )
    )
  );

  @Effect()
  init$: Observable<any> = defer(() => {
    return of(new auth.GetUser());
  });

  /** Order effects */
  /*
  @Effect()
  getOrder$ = this.actions$.pipe(
    ofType(ORder.OrderActionType.GET_ORDER),
    map((action: ORder.GetOrder) => action.payload),
    switchMap(payload =>
      this.orderService
        .getOrders(payload.customer_key, payload.status)
        .pipe(
          take(1),
          map((res: any) => {
            let order = new Order();
            order = res[0].payload.doc.data();
            order.id = res[0].payload.doc.id;
            return new ORder.SaveOrder({ order });
          }),
          catchError(error => of(new ORder.OrderError({ error })))
        )
    )
  );*/

  /** Get list orders by customerkey */

  @Effect()
  getOrderList$ = this.actions$.pipe(
    ofType(ORder.OrderActionType.GET_ORDER_LIST),
    map((action: ORder.GetOrderList) => action.payload),
    switchMap((payload: any) =>
      this.orderService.getOrderList(payload.CustomerKey).pipe(
        map((result) => {
          const Orderlist: IOrder[] = [];
          let orderD = new Order();
          result.forEach((element) => {
            orderD = element.payload.doc.data() as Order;
            orderD.id = element.payload.doc.id;
            Orderlist.push(orderD);
          });
          return new ORder.LoadOrderList({ Orderlist });
        }),
        catchError((error) => {
          return of(new ORder.OrderError({ error, orderKey: '' }));
        })
      )
    )
  );

  @Effect()
  getOrderBusinessList$ = this.actions$.pipe(
    ofType(ORder.OrderActionType.GET_ORDER_BUSINESS_LIST),
    map((action: ORder.GetOrderBusinessList) => action.payload),
    switchMap((payload: any) =>
      this.orderService.getOrderBusinessList(payload.orderKey).pipe(
        map((result) => {
          const OrderBusinessList: IOrderBusiness[] = [];
          let orderBusiness: IOrderBusiness;
          result.forEach((element) => {
            orderBusiness = element.payload.doc.data() as IOrderBusiness;
            orderBusiness.id = element.payload.doc.id;
            OrderBusinessList.push(orderBusiness);
          });
          return new ORder.LoadOrderBusinessList({ OrderBusinessList });
        }),
        catchError((error) => {
          return of(new ORder.OrderError({ error, orderKey: '' }));
        })
      )
    )
  );
  /** save Order data */
  @Effect()
  SavedOrder$ = this.actions$.pipe(
    ofType(ORder.OrderActionType.SAVED_ORDER),
    map((action: ORder.SavedOrder) => action.payload),
    switchMap(async (payload: any) => {
      if (payload.order.CustomerKey.length > 0) {
        const result = await this.orderService.createOrders(payload.order);
        // console.log(result.status);

        if (result.status === 'payment') {
          const orderData = new Order();
          return new ORder.SaveOrder({
            order: orderData,
            orderKey: result.docRef,
          });
        } else {
          this.router.navigate([result.status], {
            queryParams: { orderKey: result.docRef },
          });
          return new ORder.OrderError({
            error: result.status,
            orderKey: result.docRef,
          });
        }
        // return result.docRef;
      }
    })
  );

  @Effect({ dispatch: false })
  saveReport$ = this.actions$.pipe(
    ofType(ORder.OrderActionType.REPORT_ORDER),
    map((action: ORder.OrderReport) => action.payload),
    switchMap((payload: any) => {
      this.orderService.createReportOrderTransaction(payload.report);
      return of(payload);
    }),
    catchError((error) => of(new ORder.OrderError(error)))
  );

  /** save Order in localstorage */
  @Effect({ dispatch: false })
  saveOrder$ = this.actions$.pipe(
    ofType(ORder.OrderActionType.SAVE_ORDER),
    map((action: ORder.SaveOrder) => action.payload),
    switchMap((payload: any) => {
      this.orderService.saveOrderLocalstorage(payload.order);
      return of(payload);
    }),
    tap((payload: any) => {
      // console.log(payload.orderKey);
      this.router.navigate(['payment'], {
        queryParams: { orderKey: payload.orderKey },
      });
    })
  );

  /** error orders */
  @Effect({ dispatch: false })
  ErrorOrder = this.actions$.pipe(
    ofType(ORder.OrderActionType.ORDER_ERROR),
    map((action: ORder.OrderError) => action.payload),
    switchMap((payload: any) => {
      // this.orderService.saveOrderLocalstorage(payload.order);
      // console.log('guardar mensaje.', payload.error);
      return of(payload);
    }),
    tap((payload: any) => {
      // console.log(payload.orderKey);
      this.router.navigate(['shopping-cart'], {
        queryParams: { error: payload.error },
      });
    })
  );

  /** Product Effect getBusinessProductList */

  @Effect()
  getProducts$ = this.actions$.pipe(
    ofType(PRoducts.ProductActionType.GET_PRODUCT),
    map((action: PRoducts.GetProduct) => action.payload),
    switchMap((payload: any) =>
      this.productService
        .getBusinessProductListLimit(payload.ConsultProduct)
        .pipe(
          map((res: any) => {
            const products: Product[] = [];
            let ProducT = new Products();
            res.forEach((element) => {
              ProducT = element.payload.doc.data();
              ProducT.key = element.payload.doc.id;

              products.push(ProducT);
            });

            return new PRoducts.SaveProduct({ products });
          }),
          catchError((error) => {
            return of(new PRoducts.ErrorProduct({ error }));
          })
        )
    )
  );

  /** Address Effect */

  /** save Address */
  @Effect({ dispatch: false })
  saveAddress$ = this.actions$.pipe(
    ofType(ADdress.AddresActionType.SAVE_ADDRESS),
    map((action: ADdress.SavedAddress) => action.payload),
    switchMap((payload: any) => {
      this.addressService.createAddress(payload.Address, payload.uid);
      return of(payload);
    }),
    tap((payload: any) => {
      this.router.navigate([payload.returnUrl]);
    })
  );

  /** Get address */
  @Effect()
  getAddress$ = this.actions$.pipe(
    ofType(ADdress.AddresActionType.GET_ADDRESS),
    map((action: ADdress.GetAddress) => action.payload),
    switchMap((payload: any) =>
      this.addressService.getAddress(payload).pipe(
        map((res: any) => {
          let AddressList: any;
          res.forEach((element) => {
            // console.log(element);
            AddressList = element.payload.doc.data();
          });
          // console.log(AddressList.list);
          return new ADdress.LoadAddress({ Address: AddressList.list });
        }),
        catchError((error) => {
          return of(new ADdress.AddressError({ error }));
        })
      )
    )
  );
}
