import { Address } from '../../shared/datamodel/address.model';

export interface AddressState {
  Address: Address[] | null;
  uid: string,
}

export const AddressInitialState: AddressState = {
  Address: null,
  uid: null,
};
