import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const getOrderState = (state: AppState) => state.order;
export const getOrderlistState = (state: AppState) => state.orderlist;
export const getOrderBusinssListState = (state: AppState) => state.OrderBusinessList;

export const getOrder = createSelector(
  getOrderState,
  order => order.order
);

export const getOrderCount = createSelector(
  getOrderState,
  order => order.order.count || 0
);

export const getOrderCost = createSelector(
  getOrderState,
  order => order.order.cost || 0
);

export const getOrderAddress = createSelector(
  getOrderState,
  order => order.order.addres
);

export const getOrderItems = createSelector(
  getOrderState,
  order => order.order.items
);

export const getOrderItemBooleanBykey = (key: any) => createSelector(
  getOrderState, (order) => {
    if(order.order){
      let result = order.order.items.filter((product: { key: any; }) => product.key === key);
      return result.length > 0;
    }
  }
 
);

export const getOrderList = createSelector(
  getOrderlistState,
  Orderlist => Orderlist.Orderlist
);

export const getOrderByKey = (key) =>
  createSelector(getOrderlistState, (Orderlist) => {
    if (Orderlist.Orderlist) {
      return Orderlist.Orderlist.find((order) => {
        return order.id === key;
      });
    } else {
      return null;
    }
  });

export const getOrderBusinessList = createSelector(
  getOrderBusinssListState,
  OrderBusinessList => OrderBusinessList.OrderBusinessList
);

