import { createReducer, on } from '@ngrx/store';
import { increment, decrement, reset } from '../actions/add_item.actions';
import { initialcartState } from 'src/app/shared/datamodel/AppState.model';

const _counterReducer = createReducer(initialcartState,
                                        on(increment, state => state + 1),
                                        on(decrement, state => state - 1),
                                        on(reset, state => 0));

export function counterReducer(state, action) {
    return _counterReducer(state, action);
}

