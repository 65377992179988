export interface IOrder {
  id?: string;
  ReceiptNum: string;
  count: number;
  CustomerKey?: string;
  CustomerName?: string;
  phoneNumber?: string;
  BusinessKey?: string;
  status: number;
  PaymentMethod: string;
  payment: string;
  date?: number;
  addres: any;
  items: any;
  MapBusiness: any;
  cost: number;
  shipping: number;
  DeliveryUser: any;
}

export interface IOrderBusiness{
  id?: string;
  OrderID?: string;
  ReceiptNum: string;
  count: number;
  CustomerKey?: string;
  CustomerName?: string;
  phoneNumber?: string;
  businessName?: string;
  Businesskey?: string;
  status: number;
  PaymentMethod: string;
  payment: string;
  date?: number;
  addres: any;
  items: any;
  cost: number;
  shipping: number;
  DeliveryUser?: any;
}

export class OrderReport implements IOrder{
  DeliveryUser: any;
  id?: string;
  ReceiptNum: string;
  count: number;
  CustomerKey?: string;
  CustomerName?: string;
  phoneNumber?: string;
  BusinessKey?: string;
  status: number;
  PaymentMethod: string;
  payment: string;
  date?: number;
  addres: any;
  items: any;
  MapBusiness: any;
  cost: number;
  shipping: number;
  TransactionId?: string;
  report?: string;
}

export interface listResultOrder {
  key: string;
  img: string;
  name: string;
  quantity: number;
  price: number;
  cost: number;
}

export interface title {
  order: IOrder;
  DateTimeOrder: any;
  isSelected?: boolean;
}

export class Order implements IOrder {
  id?: string;
  ReceiptNum: string;
  count: number;
  CustomerKey: string;
  CustomerName?: string;
  phoneNumber: string;
  BusinessKey: string;
  status: number;
  date?: number;
  items: any;
  MapBusiness: any;
  addres: any;
  shipping: number;
  cost: number;
  PaymentMethod: string;
  payment: string;
  DeliveryUser: any;
  
  constructor() {
    this.id = '';
    this.ReceiptNum = '';
    this.CustomerKey = '';
    this.CustomerName = '';
    this.phoneNumber = '';
    this.BusinessKey = '';
    this.status = 0;
    this.payment = 'PENDING';
    this.PaymentMethod = 'CARD';
    this.date = new Date().getTime();
    this.items = [];
    this.MapBusiness = null;
    this.DeliveryUser = null;
    this.addres = [];
    this.count = 0;
    this.cost = 0;
    this.shipping = 0;
  }
}
