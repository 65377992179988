import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { GeolocationComponent } from './geolocation/geolocation.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const googleMapsParams = {
  apiKey: environment.apiKey,
  libraries: ['places'],
  language: 'es',
  // region: 'DE'
};


@NgModule({
  declarations: [
    GeolocationComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot(googleMapsParams),
    AgmDirectionModule
  ],
  exports: [AgmCoreModule]
})


export class AngularMapModule { }
